import React, { useRef, useState } from 'react';
import "./Qua.css";
import {motion} from "framer-motion"


const quavariants = {
    initial:{
      
      x:-300,
      opacity:0
    },
    animate:{
     
      x:0,
      opacity:1,
      transition:{
        duration:1.5,
        
        delayChildren:0.2,
        staggerChildren:0.2,
  
      },
    }
  }

const Qua = () => {


    const [toggleState,setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);

  
  }
  return (
    <motion.section className="qua section" id="qua"
    variants={quavariants}
    initial="initial"
    whileInView="animate"
    viewport={{once:true}}>
        
        <motion.h2 className="section_title" variants={quavariants}>Qualification</motion.h2>
        <motion.span className="section_subtitle" variants={quavariants}>学歴・資格</motion.span>
        <motion.div className="qua_container container" variants={quavariants}>
            <div className="qua_tabs">
                
                <div className={toggleState === 1 ? "qua_button qua_active button-flex" : "qua_button  button-flex"}
                onClick={() => toggleTab(1)}>
                    <i className="uil uil-graduation-cap qua_icon"></i>学歴
                </div>

                <div className={toggleState === 2 ? "qua_button qua_active button-flex" : "qua_button  button-flex"}
                onClick={() => toggleTab(2)}>
                    <i className="uil uil-briefcase-alt qua_icon"></i>資格
                </div>
            </div>


            <div className="qua_sections">
                <div className={toggleState === 1 ? "qua_content qua_content-active" : "qua_content"}
                >
                    <div className="qua_data">
                        
                        <div>
                            <h3 className="qua_title">東京みらいAI&IT専門学校</h3>
                            <span className="qua_subtitle">WEBクリエイター科
                            </span>
                            <div className="qua_calendar">
                            <i class="uil uil-calendar-alt"></i>2023.4入学
                            </div>
                        </div>

                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>
                    </div>

                    <div className="qua_data">
                        <div></div>
                        
                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>

                        <div>
                            <h3 className="qua_title">TCC日本語学校</h3>
                            <div className="qua_calendar">
                            <i class="uil uil-calendar-alt"></i>2023.3卒業
                            </div>
                        </div>

                    </div>

                    <div className="qua_data">
                        
                        <div>
                            <h3 className="qua_title">TCC日本語学校</h3>
                            
                            <div className="qua_calendar"><i class="uil uil-calendar-alt"></i>2021.4入学
                            </div>
                        </div>

                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>
                    </div>

                    <div className="qua_data">
                        <div></div>
                        
                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>

                        <div>
                            <h3 className="qua_title">吉林財経大学経営学部</h3>
                            <span className="qua_subtitle">物流管理学科</span>
                            <div className="qua_calendar">
                            <i class="uil uil-calendar-alt"></i>2020.7卒業
                            </div>
                        </div>

                    </div>

                    <div className="qua_data">
                       

                        <div>
                            <h3 className="qua_title">吉林財経大学経営学部</h3>
                            <span className="qua_subtitle">物流管理学科</span>
                            <div className="qua_calendar">
                            <i class="uil uil-calendar-alt"></i>2016.9入学
                            </div>
                        </div>

                        
                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>

                        
                    </div>

                   
                </div>

                <div className={toggleState === 2 ? "qua_content qua_content-active" : "qua_content"}>
                    <div className="qua_data">
                        
                        <div>
                            <h3 className="qua_title">ウェブデザイン技能検定(取得予定)</h3>
                            <span className="qua_subtitle">三級</span>
                            <div className="qua_calendar">
                            <i class="uil uil-calendar-alt"></i>2024.08.25
                            </div>
                        </div>

                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>
                    </div>

                    <div className="qua_data">
                        <div></div>
                        
                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>

                        <div>
                            <h3 className="qua_title">ITパスポート</h3>
                            <div className="qua_calendar">
                            <i class="uil uil-calendar-alt"></i>2023.10
                            </div>
                        </div>

                    </div>

                    <div className="qua_data">
                        
                        <div>
                            <h3 className="qua_title">日本語能力試験</h3>
                            <span className="qua_subtitle">N1</span>
                            <div className="qua_calendar">
                            <i class="uil uil-calendar-alt"></i>2019.12
                            </div>
                        </div>

                        <div>
                            <span className="qua_rounder"></span>
                            <span className="qua_line"></span>
                        </div>
                    </div>

                 

                   
                </div>
            </div>
        </motion.div>
    </motion.section>
  )
}

export default Qua